/**
 * @description reduce a FormSpecs object into a similar object
 * @param formSpecKeys list of keys of FormSpec object
 * @param mutation function returning a [key, value] pairing for each 'formSpecKeys' element
 * @returns the reduction of the 'formSpecKeys' array generated by the 'mutation' reducer
 */
export const reduceFormSpecs = (
  formSpecKeys: (keyof AllFormSpecs)[],
  mutation: (_: any) => [string, any] | undefined
) =>
  formSpecKeys.reduce((p, c) => {
    const mutVal = mutation(c)
    return {
      ...p,
      ...(mutVal ? { [mutVal[0]]: mutVal[1] } : {}),
    }
  }, {}) as { [_ in keyof AllFormSpecs]: any }
